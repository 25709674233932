const PhotoPreview = ({url, heading}) => {
    return (
        <div className="">
            {url &&
                <div className="">
                    <img src={url} alt="logo" className="object-cover rounded-full w-56 h-56" />
                </div>}
        </div>
    )
}
export default PhotoPreview;