import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
const API_URL = `https://cinedar.com:8443/api/notification/`;

const sendEmailWhitInformationService = async (formData) => {
    const response = await axios.post(API_URL + 'informationservice', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const notificationService = {
    sendEmailWhitInformationService
};

export default notificationService;
