import { Tooltip } from "@mui/material";
import { AiOutlineLogout } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux"
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useState } from "react";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { HiMenuAlt2 } from "react-icons/hi";
import { LOGOUT, SET_THEME } from "../store/features/slices/userSlice";
import Dropdown from "./dropdown";
import { IoInformation } from "react-icons/io5";
import { generateBackgroundColor } from "../utils/Funciones";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BsArrowBarUp } from "react-icons/bs";
import { MdNotifications } from "react-icons/md";

const AdminNav = ({ openCloseSidebar }) => {
  const dispatch = useDispatch();
  const { adminToken, configuration, userInfo, theme } = useSelector((state) => state.userReducer);

  const changeTheme = () => {
    if (localStorage.getItem('color-theme') === 'light') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      localStorage.setItem('color-theme', 'dark');
      dispatch(SET_THEME('dark'))
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
      dispatch(SET_THEME('light'))
    }
  }

  const adminLogout = () => {
    if (adminToken) {
      dispatch(LOGOUT('adminToken'));
    }
  }

  return (
    <nav className="fixed top-0 z-30 lg:ml-[.25rem] w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-lightPrimary/5 dark:bg-darkPrimary/5 p-2 backdrop-blur-lg h-24 mt-3">
      <div className="!absolute z-40 -left-1 -top-3 max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] flex items-center lg:hidden">
        <button onClick={openCloseSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button"
          className="max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px]">
          <span class="sr-only">Open sidebar</span>
          <HiMenuAlt2 size={32} className="text-brand-700 dark:text-brand-300" />
        </button>
      </div>
      <div className="absolute w-full flex items-center h-20">
        <div class="w-full pr-5 md:pr-5 lg:pr-5 flex items-center justify-end gap-3 h-20">
          {/* ADMIN notificaciones */}
          <Dropdown
            button={
              <p className="cursor-pointer">
                <MdNotifications className="h-6 w-6 text-gray-600 dark:text-white" />
              </p>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-brand-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-brand-700 dark:text-white">
                    Notificaciones
                  </p>
                  <p className="text-sm font-bold text-brand-700 dark:text-white">
                    Marcar todo leído
                  </p>
                </div>
  
                <button className="flex w-full items-center">
                  <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                    <BsArrowBarUp />
                  </div>
                  <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                    <p className="mb-1 text-left text-base font-bold text-brand-900 dark:text-white">
                      Nueva actualizacion
                    </p>
                    <p className="font-base text-left text-xs text-brand-900 dark:text-white">
                      Algo nuevo se acerca
                    </p>
                  </div>
                </button>
              </div>
            }
            classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
          />
          <div className="cursor-pointer text-gray-600"
            onClick={changeTheme}
          >
            {theme === 'dark' ? (
              <RiSunFill className="h-6 w-6 text-gray-600 dark:text-white" />
            ) : (
              <RiMoonFill className="h-6 w-6 text-gray-600 dark:text-white" />
            )}
          </div>
          <Dropdown
            button={
              userInfo?.photo === 'unknowphoto.webp' ?
                <div style={{ background: `${generateBackgroundColor(userInfo?.name)}` }} className="uppercase relative max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] rounded-full flex items-center justify-center text-white text-2xl font-normal cursor-pointer z-10">
                  {userInfo?.name.substr(0, 1)}
                </div>
                :
                <LazyLoadImage
                  className="max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] rounded-full object-cover cursor-pointer"
                  alt={'No image'}
                  effect="opacity"
                  src={`/images/photos/${userInfo?.photo}`}
                />
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-brand-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-brand-700 dark:text-white">
                      👋 Hola, {userInfo && userInfo?.name?.split(' ')[0]}
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  <div
                    onClick={adminLogout}
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in cursor-pointer"
                  >
                    Salir
                  </div>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </div>
    </nav>
  )
}
export default AdminNav;