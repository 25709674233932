import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMutation: { open: false, data: {} }
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { open: false, data: {} }
        },

        OPEN_FROM_SEARCH(state, action) {
            state.isMutation.open = true
            state.isMutation.data = action.payload
        },
    }
});

export const { OPEN_FROM_SEARCH, SET_TO_DEFAULT_RESPONSE } = searchSlice.actions;

export default searchSlice.reducer;

