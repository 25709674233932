import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import Wrapper from "./Wrapper"
import { IoEye, IoEyeOff, IoSave } from 'react-icons/io5';
import { Button, IconButton } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { message } from "../../utils/Funciones.js";
import Spinner from "../../components/Spinner";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { SET_TO_DEFAULT_RESPONSE, create } from "../../store/features/slices/userSlice";
import Swal from "sweetalert2";

const CreateUser = ({}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: '',
        email: '',
        password: '',
        countrycode: '',
        phonenumber: '',
    });

    const [VerPassword, SetVerPassword] = useState(false);

    const {
        isMutation
    } = useSelector((state) => state.userReducer);

    useEffect(() => {
        if (isMutation.success) {
            message(isMutation.message, 'success', 5)
            dispatch(SET_TO_DEFAULT_RESPONSE())
            alertaConfirmar('Confirmar', 'Quieres seguir registrando usuarios?', 'question', async (res_alert) => {
                if (res_alert === true) {
                    setState({
                        name: '',
                        email: '',
                        password: '',
                        countrycode: '',
                        phonenumber: '',
                    })
                } else if (res_alert === false) {
                    navigate('/admin/users');
                }
            })
        }
    }, [isMutation.success])

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showDenyButton: true,
            confirmButtonText: 'Si, continuar',
            denyButtonText: 'No, ir a usuarios',
            confirmButtonColor: '#2C3E50',
        }).then((result) => {
            if (result.isConfirmed) {
                callback(result.isConfirmed)
            } else if (result.isDenied) {
                callback(false)
            }
        })
    }

    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const onSubmitNewUser = e => {
        e.preventDefault();
        dispatch(create(state));
    }

    return (
        <Wrapper>

            {/* HEADER PAGES */}
            <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
                Nuevo usuario
            </div>
            <div className="flex flex-wrap mt-12 gap-4">
                <form className="w-full xl:w-8/12" onSubmit={onSubmitNewUser}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre del usuario</label>
                            <input autoFocus name="name" className="custom-style-input"
                                autoComplete="off"
                                required
                                value={state.name}
                                onChange={onChange}
                                type="text"
                            />
                        </div>

                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo</label>
                            <input name="email" className="custom-style-input"
                                autoComplete="off"
                                required
                                value={state.email}
                                onChange={onChange}
                                type="email"
                            />
                        </div>

                        <div className="w-full relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password</label>
                            <input name="password" className="custom-style-input"
                                autoComplete="off"
                                required
                                value={state.password}
                                onChange={onChange}
                                type={VerPassword ? 'text' : 'password'}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                {<IconButton color='primary' onClick={() => SetVerPassword(!VerPassword)}>
                                    {VerPassword ? <IoEyeOff color='#c2c2c2' /> : <IoEye color='#c2c2c2' />}
                                </IconButton>}
                            </div>
                        </div>

                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Numero celular</label>
                            <MuiTelInput
                                inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                                value={state.phonenumber}
                                defaultCountry={'PE'}
                                onChange={(newValue, info) => setState({ ...state, countrycode: info.countryCode, phonenumber: newValue })}
                                className="custom-style-input"
                            />
                        </div>
                    </div>
                    <div className="flex m-auto w-full">
                        <Button
                            type="submit"
                            disabled={isMutation.loading ? true : false}
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained"
                        >
                            {isMutation.loading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                            {isMutation.loading ? 'Guardando...' : 'Registrar USUARIO'}
                        </Button>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}
export default CreateUser