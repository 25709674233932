import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationService from "../services/notificationService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: null },
};

export const sendEmailWhitInformationService = createAsyncThunk(
    "notification/sendEmailWhitInformationService/informationservice",
    async (formData, thunkAPI) => {
        try {
            return await notificationService.sendEmailWhitInformationService(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: null }
        }
    },
    extraReducers: (builder) => {
        builder

            // ENVIAR EMAIL CON INFORMACION DEL SERVICIO

            .addCase(sendEmailWhitInformationService.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(sendEmailWhitInformationService.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                state.isMutation.success = true;
            })
            .addCase(sendEmailWhitInformationService.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { SET_TO_DEFAULT_RESPONSE } = notificationSlice.actions;

export default notificationSlice.reducer;

