import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import Wrapper from "./Wrapper"
import Spinner from "../../components/Spinner"
import { IoArrowDown, IoChevronDown, IoEye, IoEyeOff, IoPerson, IoSave } from 'react-icons/io5';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { BsArrowDown, BsInfo, BsPlusCircleDotted } from "react-icons/bs";
import { getLocalDateFromNow, message, PermiteSoloNumeros } from "../../utils/Funciones.js";
import moment from 'moment';
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import { SET_TO_DEFAULT_RESPONSE, create } from "../../store/features/slices/cuentaSlice";
const { v4: uuidv4 } = require("uuid");

const CreateCuenta = ({}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        categories,
        subcategories,
        isMutation: loadingcategory
    } = useSelector((state) => state.categoryReducer);

    const {
        isMutation
    } = useSelector((state) => state.cuentaReducer);

    const [state, setState] = useState({
        correo: '',
        passwordcorreo: '',
        passwordcuenta: '',
        categoryId: '',
        subcategoryId: '',
        expirationdate: getLocalDateFromNow(30),
        observations: '',
        servicetype: null,
        profilesinfo: []
    });

    const [VerPasswords, SetVerPasswords] = useState(
        {
            passwordcorreo: false,
            passwordcuenta: false,
            passwordp1: false,
            passwordp2: false,
            passwordp3: false,
            passwordp4: false,
            passwordp5: false,
            passwordp6: false,
            passwordp7: false,
            passwordp8: false,
            passwordp9: false,
            passwordp10: false,
        });

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showDenyButton: true,
            confirmButtonText: 'Si, continuar',
            denyButtonText: 'No, ir a cuentas',
            confirmButtonColor: '#2C3E50',
        }).then((result) => {
            if (result.isConfirmed) {
                callback(result.isConfirmed)
            } else if (result.isDenied) {
                callback(false)
            }
        })
    }

    const handleInput = e => {

        if (e.target.name === 'categoryId') {
            setState({
                ...state,
                [e.target.name]: e.target.value,
                ['subcategoryId']: "",
                ['profilesinfo']: []
            })
        } else if (e.target.name === "subcategoryId") {
            var val = false
            var maximoperfiles = 0
            !loadingcategory.loading && subcategories.find((cat) => {
                if (cat._id === e.target.value) {
                    val = cat.isprofileblocked
                    maximoperfiles = cat.maximoperfiles
                }
            })
            const datas = []
            if (val) {
                if (state.servicetype) {
                    var perfiles = {
                        _id: uuidv4(),
                        name: "Cliente",
                        password: "",
                        isused: false,
                        byuserid: ""
                    }
                    datas.push(perfiles)
                    setState({
                        ...state,
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                } else {
                    for (var i = 1; i <= maximoperfiles; i++) {
                        var AleatoryPasswords = Math.floor((Math.random() * (9999 - 1001 + 1)) + 1001)

                        var perfiles = {
                            _id: uuidv4(),
                            name: "Cliente " + i,
                            password: AleatoryPasswords,
                            isused: false,
                            byuserid: ""
                        }
                        datas.push(perfiles)
                    }
                    setState({
                        ...state,
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                }
            } else {
                setState({
                    ...state,
                    [e.target.name]: e.target.value,
                    ['profilesinfo']: []
                })
            }
        } else if (e.target.name === "servicetype" && state.categoryId !== "" && state.subcategoryId !== "") {
            var val = false
            var maximoperfiles = 0
            !loadingcategory.loading && subcategories.find((cat) => {
                if (cat._id === state.categoryId) {
                    val = cat.isprofileblocked
                    maximoperfiles = cat.maximoperfiles
                }
            })
            const datas = []
            if (val) {
                if (e.target.value) {
                    var perfiles = {
                        _id: uuidv4(),
                        name: "Cliente",
                        password: "",
                        isused: false,
                        byuserid: ""
                    }
                    datas.push(perfiles)
                    setState({
                        ...state,
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                } else {
                    for (var i = 1; i <= maximoperfiles; i++) {
                        var AleatoryPasswords = Math.floor((Math.random() * (9999 - 1001 + 1)) + 1001)

                        var perfiles = {
                            _id: uuidv4(),
                            name: "Cliente " + i,
                            password: AleatoryPasswords,
                            isused: false,
                            byuserid: ""
                        }
                        datas.push(perfiles)
                    }
                    setState({
                        ...state,
                        categoryId: '',
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                }

            } else {
                setState({
                    ...state,
                    categoryId: '',
                    [e.target.name]: e.target.value,
                    ['profilesinfo']: []
                })
            }
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
    }

    const HandleChangeProfileInfo = (value, name, id) => {
        const NewState = state.profilesinfo.map((pro) => {
            if (pro._id === id) {
                return {
                    ...pro,
                    [name]: value
                }
            }
            return pro
        })
        setState({ ...state, profilesinfo: NewState })
    }

    const createCuenta = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        dispatch(create(formData));
    }

    useEffect(() => {
        if (isMutation.success) {
            message(isMutation.message, 'success', 5)
            Socket.emit('uSubCategory', { newData: isMutation.extra.subcategory })
            dispatch(SET_TO_DEFAULT_RESPONSE())
            alertaConfirmar('CONFIRMAR', 'Quieres seguir registrando cuentas?', 'question', async (res_alert) => {
                if (res_alert === true) {
                    setState({
                        correo: '',
                        passwordcorreo: '',
                        passwordcuenta: '',
                        categoryId: '',
                        expirationdate: getLocalDateFromNow(30),
                        observations: '',
                        servicetype: null,
                        profilesinfo: [],
                    })
                } else if (res_alert === false) {
                    navigate('/admin/cuentas');
                }
            })
        }
    }, [isMutation.success])

    return (
        <Wrapper>

            {/* HEADER PAGES */}
            <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
                Nueva cuenta
            </div>
            <div className="flex flex-wrap mt-12 gap-4">
                <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                    <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Info</span>
                    <div>
                        <span class="font-bold uppercase">Importante!</span> Las subcategorías que tienen como máximo 1 perfil, solamente pueden ser cuentas completas.
                    </div>
                </div>
                <form className="w-full xl:w-8/12" onSubmit={createCuenta}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo o usuario de la cuenta</label>
                            <input autoFocus name="correo" className="custom-style-input"
                                autoComplete="off"
                                required
                                value={state.correo}
                                onChange={handleInput}
                                type="text"
                            />
                        </div>
                        <div className="w-full relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password del correo <span className="text-xs text-amber-600 dark:text-amber-400">(opcional)</span></label>
                            <input name="passwordcorreo" className="custom-style-input"
                                autoComplete="off"
                                value={state.passwordcorreo}
                                onChange={handleInput}
                                type={VerPasswords.passwordcorreo ? 'text' : 'password'}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcorreo']: !VerPasswords.passwordcorreo })}>
                                    {VerPasswords.passwordcorreo ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                                </IconButton>}
                            </div>
                        </div>

                        <div className="w-full relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password de la cuenta</label>
                            <input name="passwordcuenta" className="custom-style-input"
                                autoComplete="off"
                                required
                                value={state.passwordcuenta}
                                onChange={handleInput}
                                type={VerPasswords.passwordcuenta ? 'text' : 'password'}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcuenta']: !VerPasswords.passwordcuenta })}>
                                    {VerPasswords.passwordcuenta ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                                </IconButton>}
                            </div>
                        </div>

                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Expiración de la cuenta <span className="text-xs text-amber-600 dark:text-amber-400">(30 dias por defecto)</span></label>
                            <input name="expirationdate" className="custom-style-input"
                                autoComplete="off"
                                required
                                value={state.expirationdate}
                                onChange={handleInput}
                                type="datetime-local"
                            />
                        </div>

                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Tipo de servicio</label>
                            {!loadingcategory.loading ? categories.length > 0 &&
                                <div className="relative">
                                    <FormControl fullWidth>
                                        <Select
                                            required
                                            name="servicetype"
                                            className="flex items-center"
                                            variant="outlined"
                                            value={state.servicetype}
                                            onChange={handleInput}
                                        >
                                            <MenuItem value={true} key={true} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/completa.webp`} className="mr-2 rounded-full" />Cuenta completa</MenuItem>
                                            <MenuItem value={false} key={false} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/pantalla.webp`} className="mr-2 rounded-full" />Cuenta compartida</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div> : <Spinner />}

                        </div>

                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Categoria</label>
                            {
                                state.servicetype !== null ?
                                    <div className="relative">
                                        <FormControl fullWidth>
                                            <Select
                                                required
                                                name="categoryId"
                                                className="flex items-center"
                                                variant="outlined"
                                                value={state.categoryId}
                                                onChange={handleInput}
                                            >
                                                {!loadingcategory.loading && categories.filter((fil) => fil.type === 'Streaming').map(category => (
                                                    <MenuItem value={category._id} key={category._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.sublogo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {
                                            /*state.servicetype === 'completa' ?
                                                !loadingcategory.loading && categories.filter(cat => cat.cuentacompleta).map(category => (

                                                    <option value={category._id} key={category._id}>{category.name}</option>

                                                )) :
                                                !loadingcategory.loading && categories.filter(cat => !cat.cuentacompleta).map(category => (

                                                    <option value={category._id} key={category._id}>{category.name}</option>

                                                ))*/

                                        }
                                    </div> : <div className="mt-7 text-brand-900 dark:text-brand-200">Seleccionar tipo de servicio</div>}

                        </div>
                        <div className="w-full md:w-[calc(50%-10px)] md:col-span-2">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Sub categoría</label>
                            {
                                state.categoryId !== "" ?
                                    <div className="relative">
                                        <FormControl fullWidth>
                                            <Select
                                                required
                                                name="subcategoryId"
                                                className="flex items-center"
                                                variant="outlined"
                                                value={state.subcategoryId}
                                                onChange={handleInput}
                                            >
                                                {!loadingcategory.loading && subcategories.filter(fil => (fil.category?._id === state.categoryId && fil.cuentacompleta === state.servicetype)).map(category => (
                                                    <MenuItem value={category._id} key={category._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.logo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> : <div className="mt-7 text-brand-900 dark:text-brand-200">Seleccionar categoría</div>}
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full md:w-12/12 md:col-span-2">
                            {
                                state?.profilesinfo?.map((pro, i) => {
                                    var passwordp = `passwordp${i + 1}`
                                    var categoryname = `name`
                                    var password = `password`

                                    return (
                                        <div className="rounded-lg relative text-brand-900 dark:text-brand-200 bg-brand-100 dark:bg-brand-900 shadow-md shadow-brand-500 dark:shadow-black p-4 cursor-pointer hover:scale-[101%] animation transition-all">

                                            <div className="absolute text-center font-bold rounded-full p-2 left-1 top-1 bg-brand-400 dark:bg-brand-800 text-brand-950 dark:text-brand-50 bg-opacity-30 text-3xl w-14 h-14 flex items-center justify-center">
                                                {i + 1}
                                            </div>
                                            <IoPerson size={64} className="text-brand-900 dark:text-brand-200 rounded-full m-auto" />

                                            <div className="m-auto mb-2">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input key={i} name={categoryname} className="bg-brand-300 dark:bg-brand-700 p-[15px] text-brand-900 dark:text-brand-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                    placeholder="Nombre"
                                                    autoComplete="off"
                                                    required
                                                    onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                                                    value={pro[categoryname]}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="m-auto relative">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">PIN</label>
                                                <input key={i} name={password} className="bg-brand-300 dark:bg-brand-700 p-[15px] text-brand-900 dark:text-brand-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                    placeholder="PIN"
                                                    autoComplete="off"
                                                    required
                                                    onKeyPress={PermiteSoloNumeros}
                                                    onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                                                    value={pro[password]}
                                                    type={VerPasswords[passwordp] ? 'text' : 'password'}
                                                    maxLength={5}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    {<IconButton color='primary' onClick={(e) => SetVerPasswords({ ...VerPasswords, [passwordp]: !VerPasswords[passwordp] })}>
                                                        {VerPasswords[passwordp] ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                                                    </IconButton>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="w-full">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Observaciones <span className="text-xs text-amber-600 dark:text-amber-400">(opcional)</span></label>
                            <textarea name="observations" className="custom-style-input"
                                rows="3"
                                autoComplete="off"
                                value={state.observations}
                                onChange={handleInput}
                            />
                        </div>
                    </div>
                    <div className="flex m-auto w-full">
                        <Button
                            type="submit"
                            disabled={isMutation.loading ? true : false}
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained"
                        >
                            {isMutation.loading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                            {isMutation.loading ? 'Guardando...' : 'Registrar CUENTA'}
                        </Button>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}
export default CreateCuenta