import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Wrapper from "./Wrapper"
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { message } from "../../utils/Funciones.js";
import Spinner from "../../components/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaPlusCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { SET_TO_DEFAULT_RESPONSE, create } from "../../store/features/slices/giftcardSlice";
import { IoSave } from "react-icons/io5";

const CreateGiftCard = ({}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        categoryId: "",
        subcategoryId: "",
        code: "",
    });

    const {
        categories,
        subcategories,
        isMutation
    } = useSelector((state) => state.categoryReducer);

    const {
        isMutation: isMutationGc
    } = useSelector((state) => state.giftcardReducer);

    useEffect(() => {
        if (isMutationGc.success) {
            message(isMutationGc.message, 'success', 5)
            dispatch(SET_TO_DEFAULT_RESPONSE())
            alertaConfirmar('Confirmar', 'Quieres seguir registrando trajetas de regalo?', 'question', async (res_alert) => {
                if (res_alert === true) {
                    setState({
                        categoryId: "",
                        subcategoryId: "",
                        code: "",
                    })
                } else if (res_alert === false) {
                    navigate('/admin/giftcards');
                }
            })
        }
    }, [isMutationGc.success])


    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showDenyButton: true,
            confirmButtonText: 'Si, continuar',
            denyButtonText: 'No, ir a tarjetas de regalo',
            confirmButtonColor: '#2C3E50',
        }).then((result) => {
            if (result.isConfirmed) {
                callback(result.isConfirmed)
            } else if (result.isDenied) {
                callback(false)
            }
        })
    }

    const HandleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const submitGiftCard = e => {
        e.preventDefault();
        dispatch(create(state));
    }

    return (
        <Wrapper>

            {/* HEADER PAGES */}
            <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
                Nueva tarjeta de regalo
            </div>

            <div className="flex flex-wrap -mx-3 mt-12">
                <form className="w-full xl:w-8/12" onSubmit={submitGiftCard}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Categoria</label>
                            <div className="relative">
                                <FormControl fullWidth>
                                    <Select
                                        required
                                        name="categoryId"
                                        className="flex items-center"
                                        variant="outlined"
                                        value={state.categoryId}
                                        onChange={HandleChange}
                                    >
                                        {!isMutation.loading && categories.filter(fil => fil.type === 'GiftCard').map(cat => (
                                            <MenuItem value={cat._id} key={cat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${cat.sublogo}`} className="mr-2 rounded-full" />{cat.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Sub Categoria</label>
                            <div className="relative">
                                <FormControl fullWidth>
                                    <Select
                                        required
                                        name="subcategoryId"
                                        className="flex items-center"
                                        variant="outlined"
                                        value={state.subcategoryId}
                                        onChange={HandleChange}
                                    >
                                        {subcategories.filter((fil) => fil.category?._id === state.categoryId).map(subcat => (
                                            <MenuItem value={subcat._id} key={subcat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcat.logo}`} className="mr-2 rounded-full" />{subcat.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="w-full md:w-12/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Códigos</label>
                            <textarea name="code" className="custom-style-input"
                                autoComplete="off"
                                required
                                type="text"
                                value={state.code}
                                onChange={HandleChange}
                            />
                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Puedes ingresar los códigos separados por una coma, ejemplo: ABC1,ABC2,ABC3, ...</p>
                        </div>
                    </div>
                    <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                        <Button
                            type="submit"
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained" color="primary"
                            disabled={isMutationGc.loading ? true : false}
                        >
                            {isMutationGc.loading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                            {isMutationGc.loading ? 'Guardando...' : 'REGISTRAR TARJETA DE REGALO'}
                        </Button>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}
export default CreateGiftCard;