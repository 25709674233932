import { io } from 'socket.io-client';
import { BACKEND } from './Constants';

const socket = io(BACKEND)

export const authSocket = (token) => {
  socket.emit('auth', token)
}

export const logoutSocket = (userId) => {
  socket.emit('logout', userId)
}

export default socket;
