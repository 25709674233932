import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import giftcardService from "../services/giftcardService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: null },
    contador: { free: 0, used: 0, total: 0, loading: false },
    giftcardsByPage: [],
    giftcards: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
};

export const create = createAsyncThunk(
    "giftcard/create",
    async (formData, thunkAPI) => {
        try {
            return await giftcardService.create(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAll = createAsyncThunk(
    "giftcard/getAll",
    async (_, thunkAPI) => {
        try {
            return await giftcardService.getAll();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBypage = createAsyncThunk(
    "giftcard/getBypage/giftcardsbypage",
    async (query, thunkAPI) => {
        try {
            return await giftcardService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "giftcard/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await giftcardService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const update = createAsyncThunk(
    "giftcard/update/giftcard",
    async (formData, thunkAPI) => {
        try {
            return await giftcardService.update(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const del = createAsyncThunk(
    "giftcard/del/giftcard",
    async (id, thunkAPI) => {
        try {
            return await giftcardService.del(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const giftcardSlice = createSlice({
    name: "giftcard",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: null }
        },

        UPDATE_GIFTCARD_EXTERNAL(state, action) {
            const newState = state.giftcardsByPage.map((giftcard) => {
                if (giftcard._id === action.payload.giftcard._id) {
                    giftcard = action.payload.giftcard
                }
                return giftcard
            })
            state.giftcardsByPage = newState
        },

        UPDATE_GIFTCARD_FROM_SOCKET(state, action) {

        },
    },
    extraReducers: (builder) => {
        builder

            // CREATE

            .addCase(create.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                state.isMutation.success = true;
            })
            .addCase(create.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET ALL

            .addCase(getAll.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.giftcards = action.payload;
            })
            .addCase(getAll.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.giftcardsByPage = action.payload.giftcards;
                state.totalFilter = action.payload.totalgiftcardsFilter;
                state.total = action.payload.totalgiftcards;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.free = action.payload.totalgiftcardsFree;
                state.contador.used = action.payload.totalgiftcardsUsed;
                state.contador.total = action.payload.totalgiftcards;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // UPDATE

            .addCase(update.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.cuentasByPage.map((cuenta) => {
                    if (cuenta._id === action.payload.newData._id) {
                        cuenta = action.payload.newData
                    }
                    return cuenta
                })
                state.cuentasByPage = newState
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(update.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // DELETE

            .addCase(del.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(del.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })

            .addCase(del.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { UPDATE_GIFTCARD_FROM_SOCKET, SET_TO_DEFAULT_RESPONSE, UPDATE_GIFTCARD_EXTERNAL } = giftcardSlice.actions;

export default giftcardSlice.reducer;

