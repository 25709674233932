import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Transition from '../utils/Transition';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoEllipse } from 'react-icons/io5';
import { OPEN_FROM_SEARCH } from '../store/features/slices/searchSlice';
import { MenuItem } from '@mui/material';

function ModalSearch({
    id,
    searchId,
    modalOpen,
    setModalOpen
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const modalContent = useRef(null);
    const searchInput = useRef(null);
    const { subcategories } = useSelector((state) => state.categoryReducer);
    const { services } = useSelector((state) => state.socialReducer);
    const { isMutation } = useSelector((state) => state.searchReducer);
    const [searchTerm, setSearchTerm] = useState('')
    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!modalOpen || modalContent.current.contains(target)) return
            setModalOpen(false);
            setSearchTerm('');
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!modalOpen || keyCode !== 27) return;
            setModalOpen(false);
            setSearchTerm('');
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        modalOpen && searchInput.current.focus();
    }, [modalOpen]);

    const handleFilters = subcategories.concat(services.map((item) => ({ ...item, type: 'Social' }))).filter(_c => {
        if (searchTerm) {
            return _c.name.toLowerCase().includes(searchTerm.toLowerCase())
        }
    })

    const handleOpenFromSearch = (item) => {
        setModalOpen(false);
        setSearchTerm('');
        dispatch(OPEN_FROM_SEARCH(item))
    }

    return (
        <>
            {/* Modal backdrop */}
            <Transition
                className="fixed inset-0 bg-[#00000098] dark:bg-[#ffffff98] bg-opacity-20 dark:bg-opacity-20 backdrop-blur-[3px] dark:backdrop-blur-[3px] z-50 transition-opacity"
                show={modalOpen}
                enter="transition ease-out duration-200"
                enterStart="opacity-0"
                enterEnd="opacity-100"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                aria-hidden="true"
            />
            {/* Modal dialog */}
            <Transition
                id={id}
                className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
                role="dialog"
                aria-modal="true"
                show={modalOpen}
                enter="transition ease-in-out duration-200"
                enterStart="opacity-0 translate-y-4"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 translate-y-4"
            >
                <div
                    ref={modalContent}
                    className="bg-white dark:bg-brand-900 border border-transparent dark:border-brand-700/60 overflow-auto max-w-2xl w-full max-h-full rounded-lg shadow-lg"
                >
                    {/* Search form */}
                    <form className="border-b border-brand-200 dark:border-brand-700/60 p-1">
                        <div className="relative">
                            <label htmlFor={searchId} className="sr-only">
                                Buscar
                            </label>
                            <input
                                id={searchId}
                                className="custom-style-input pl-10 placeholder-brand-300 dark:placeholder-brand-700"
                                type="search"
                                placeholder="Escribe lo que estas buscando…"
                                ref={searchInput}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button className="absolute inset-0 right-auto group" type="button" aria-label="Search">
                                <svg
                                    className="shrink-0 fill-current text-brand-400 dark:text-brand-500 group-hover:text-brand-500 dark:group-hover:text-brand-400 ml-4 mr-2"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                    <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                </svg>
                            </button>
                        </div>
                    </form>
                    <div className="py-4 px-2">
                        {/* Recent searches */}
                        <div className="mb-3 last:mb-0">
                            <div className="text-xs font-semibold text-brand-400 dark:text-brand-500 uppercase px-2 mb-2">Resultados</div>
                            <ul className="text-sm">
                                {
                                    handleFilters.map((item) => {
                                        return (
                                            <MenuItem sx={{ height: 40 }} disabled={(!item.enabled || (item.plataform && !item.plataform?.enabled) || (item.category && !item.category?.enabled) || (item.stock === 0 && item.tipoentrega)) ? true : false} onClick={() => handleOpenFromSearch(item)} className='!text-sm cursor-pointer text-brand-950 dark:text-brand-50 flex items-center p-3 bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800'>
                                                <IoEllipse className={`${(!item.enabled || (item.plataform && !item.plataform?.enabled) || (item.category && !item.category?.enabled) || (item.stock === 0 && item.tipoentrega)) ? 'text-lightError dark:text-darkError' : 'text-lightPrimary dark:text-darkPrimary'} mr-2`} />
                                                {item.name}{(!item.enabled || (item.plataform && !item.plataform?.enabled) || (item.category && !item.category?.enabled) || (item.stock === 0 && item.tipoentrega)) && <span className='text-lightError dark:text-darkError ml-2'>no disponible</span>}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
}

export default ModalSearch;