const Switch = (props) => {
  const { value, onChange } = props;

  return (
      <label class="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={value} onChange={onChange} class="sr-only peer" />
        <div className={`group peer ring-0 bg-rose-400  rounded-full outline-none duration-300 after:duration-300 w-20 h-10  shadow-md peer-checked:bg-emerald-500  peer-focus:outline-none  after:content-['✖️']  after:rounded-full after:absolute after:bg-gray-50 after:outline-none after:h-8 after:w-8 after:top-1 after:left-1 after:-rotate-180 after:flex after:justify-center after:items-center peer-checked:after:translate-x-10 peer-checked:after:content-['✔️'] peer-hover:after:scale-95 peer-checked:after:rotate-0`}>
        </div>
      </label>
  );
};

export default Switch;
