import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { useDispatch, useSelector } from "react-redux";
import { MuiTelInput } from 'mui-tel-input'
import { MdMail } from "react-icons/md";
import { Button, IconButton, TextField } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { IoEye, IoEyeOff, IoFingerPrint, IoPerson } from "react-icons/io5";
import Nav from "../../../components/home/Nav";
import { message } from "../../../utils/Funciones.js";
import Spinner from "../../../components/Spinner";
import { register } from "../../../store/features/slices/userSlice";
import { FaFingerprint } from "react-icons/fa6";
const moment = require("moment");
require("moment/locale/es");

const Register = ({ }) => {
    const dispatch = useDispatch();
    const { isMutation, configuration, userToken, adminToken } = useSelector((state) => state.userReducer);
    const [state, Setstate] = useState({
        name: '',
        email: '',
        password: '',
        countrycode: '',
        phonenumber: '',
    });

    const [VerPasswords, SetVerPasswords] = useState(
        {
            password: false,
        });

    const onChange = (e) => {
        Setstate({ ...state, [e.target.name]: e.target.value });
    };

    const onChangePhone = (newValue, info) => {
        Setstate({ ...state, ['countrycode']: info.countryCode, ['phonenumber']: newValue });
    };

    const onSubmit = e => {
        e.preventDefault();
        if (userToken || adminToken) {
            message('existe una sesión abierta, porfavor cierra y vuelve a intentar.', 'error', 5)
        } else {
            if(configuration?.register){
                dispatch(register(state))
            } else {
                message('el registro se encuentra en mantenimiento, por favor reintenta en unos minutos.', 'error', 5)
            }
        }
    }

    return (
        <>
            <Nav />

            <div class="min-h-screen bg-brand-50 dark:bg-brand-950 py-6 flex flex-col justify-center sm:py-12">
                <div class="relative py-3 px-4 sm:max-w-xl sm:mx-auto">
                    <div
                        class="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                    </div>
                    <div class="relative px-4 py-10 bg-brand-50 dark:bg-brand-950 shadow-lg sm:rounded-3xl sm:p-20">

                        <div class="w-full">
                            <div>
                                <h1 class="text-2xl font-semibold text-brand-950 dark:text-brand-50">Registrarse</h1>
                            </div>
                            <form onSubmit={onSubmit} >
                                <div class="divide-y divide-brand-200">
                                    <div class="py-8 text-base leading-6 space-y-4 text-brand-700 sm:text-lg sm:leading-7">
                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                            <input autoFocus name="name" className="custom-style-input pl-10"
                                                autoComplete="off"
                                                required
                                                value={state.name}
                                                onChange={onChange}
                                                type="text"
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center leading-5">
                                                <FaUser size={28} className="text-brand-800 dark:text-brand-200 ml-1" />
                                            </div>
                                        </div>

                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo</label>
                                            <input name="email" className="custom-style-input pl-10"
                                                autoComplete="off"
                                                required
                                                value={state.email}
                                                onChange={onChange}
                                                type="email"
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center leading-5">
                                                <MdMail size={28} className="text-brand-800 dark:text-brand-200 ml-1" />
                                            </div>
                                        </div>

                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password</label>
                                            <input name="password" className="custom-style-input pl-10"
                                                autoComplete="off"
                                                required
                                                onChange={onChange}
                                                value={state.password}
                                                type={VerPasswords.password ? 'text' : 'password'}
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center text-sm leading-5">
                                                <FaFingerprint size={28} className="text-brand-900 dark:text-brand-200 ml-1" />
                                            </div>
                                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['password']: !VerPasswords.password })}>
                                                    {VerPasswords.password ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                                                </IconButton>}
                                            </div>
                                        </div>

                                        <div className="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Numero celular</label>
                                            <MuiTelInput
                                                className="bg-brand-100 dark:bg-brand-900 shadow-md shadow-brand-500 dark:shadow-black p-[15px] text-brand-900 dark:text-brand-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                helperText={'Por favor use su numero de whatsapp, le enviaremos información importante a este numero. Gracias.'}
                                                required
                                                inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                                                value={state.phonenumber}
                                                defaultCountry={'PE'}
                                                onChange={onChangePhone}
                                            />
                                        </div>

                                        <div class="relative">
                                            <Button
                                                type="submit"
                                                disabled={isMutation.sloading ? true : false}
                                                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                                size='large' variant="contained"
                                            >
                                                {isMutation.sloading ? <Spinner /> : <FaUserPlus size={24} className="mr-2" />}
                                                {isMutation.sloading ? 'Ingresando...' : 'REGISTRARSE'}
                                            </Button>
                                        </div>

                                        <div className="text-base mt-4 text-brand-400 m-auto w-full text-center">Ya tienes una cuenta ?
                                            <span className="text-brand-800 dark:text-brand-200 ml-2">
                                                <Link to="/login">
                                                    Ingresar
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Register;