import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import socialService from "../services/socialService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, vloading: false, message: '', updated: false, uloading: false, deleted: false, dloading: false, aloading: false, extra: {}, sloading: false },
    contador: { pending: 0, rejected: 0, total: 0, loading: false },
    plataforms: [],
    services: [],
    serviceAPI: {},
    message: "",
    totalStoreValue: 0,
    ventasByPage: [],
    ventas: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
};

export const create = createAsyncThunk(
    "social/create",
    async (formData, thunkAPI) => {
        try {
            return await socialService.create(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createorder = createAsyncThunk(
    "social/createorder/createorder",
    async (formData, thunkAPI) => {
        try {
            return await socialService.createorder(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const emitirOrder = createAsyncThunk(
    "social/emitirOrder/emitirorder",
    async (formData, thunkAPI) => {
        try {
            return await socialService.emitirOrder(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getPlataforms = createAsyncThunk(
    "social/getPlataforms/plataforms",
    async (_, thunkAPI) => {
        try {
            return await socialService.getPlataforms();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getServices = createAsyncThunk(
    "social/getServices/services",
    async (_, thunkAPI) => {
        try {
            return await socialService.getServices();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getServiceApiById = createAsyncThunk(
    "social/getServiceApiById/servicesapi",
    async (query, thunkAPI) => {
        try {
            return await socialService.getServiceApiById(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBypage = createAsyncThunk(
    "social/getBypage/ventabypage",
    async (query, thunkAPI) => {
        try {
            return await socialService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getByPageByUserId = createAsyncThunk(
    "social/getByPageByUserId/ventasbyuserId",
    async (query, thunkAPI) => {
        try {
            return await socialService.getByPageByUserId(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "social/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await socialService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const updatePlataform = createAsyncThunk(
    "social/updatePlataform/plataform",
    async (formData, thunkAPI) => {
        try {
            return await socialService.updatePlataform(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateService = createAsyncThunk(
    "social/updateService/service",
    async (formData, thunkAPI) => {
        try {
            return await socialService.updateService(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createMessageReport = createAsyncThunk(
    "social/createMessageReport/message_report",
    async (formData, thunkAPI) => {
        try {
            return await socialService.createMessageReport(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateSocialOrder = createAsyncThunk(
    "social/updateSocialOrder/updatesocialorder",
    async (formData, thunkAPI) => {
        try {
            return await socialService.updateSocialOrder(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deletePlataform = createAsyncThunk(
    "social/deletePlataform/plataform",
    async (id, thunkAPI) => {
        try {
            return await socialService.deletePlataform(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteService = createAsyncThunk(
    "social/deleteService/service",
    async (id, thunkAPI) => {
        try {
            return await socialService.deleteService(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const socialSlice = createSlice({
    name: "social",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, vloading: false, message: '', updated: false, uloading: false, deleted: false, dloading: false, aloading: false, extra: {}, sloading: false }
        },

        SET_PLATAFORM_SERVICE(state, action) {
            state.plataforms = action.payload.plataforms;
            state.services = action.payload.services;
        },

        UPDATE_LAST10_OF_PLATAFORM(state, action) {
            const array = state.plataforms.filter((fil) => fil._id === action.payload.plataform)[0].history;
            if (array.length >= 10) {
                array.pop();
            }

            array.unshift({ history: action.payload });

            const newState = state.plataforms.map((plataform) => {
                if (plataform._id === action.payload.plataform) {
                    plataform.history = array
                }
                return plataform
            })

            state.plataforms = newState;
        },

        UPDATE_SOCIAL_FROM_SOCKET(state, action) {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                if (action.payload.type === 'NewSocialService') {
                    state.services.push(action.payload.newData)
                } else {
                    state.plataforms.push(action.payload.newData);
                }
                state.isMutation.extra.type = action.payload.type
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })
            .addCase(create.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CREATE ORDER

            .addCase(createorder.pending, (state) => {
                state.isMutation.vloading = true;
            })
            .addCase(createorder.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.amount = action.payload.amount;
                state.isMutation.extra.newHistory = action.payload.newHistory;
                state.isMutation.vloading = false;
                state.isMutation.success = true;
            })
            .addCase(createorder.rejected, (state, action) => {
                state.isMutation.vloading = false;
            })

            // EMITIR ORDER

            .addCase(emitirOrder.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(emitirOrder.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        return {
                            ...venta,
                            state: action.payload.newData.state,
                            orderId: action.payload.newData.orderId
                        }
                    }
                    return venta
                })
                state.ventasByPage = newState;
                state.isMutation.extra.emited = true;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(emitirOrder.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })


            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.ventasByPage = action.payload.ventas;
                state.totalFilter = action.payload.totalventasFilter;
                state.total = action.payload.totalventas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.isMutation.loading = false;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BY_USER_ID

            .addCase(getByPageByUserId.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getByPageByUserId.fulfilled, (state, action) => {
                state.ventasByPage = action.payload.ventas;
                state.totalFilter = action.payload.totalventasFilter;
                state.total = action.payload.totalventas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.isMutation.loading = false;
            })
            .addCase(getByPageByUserId.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.pending = action.payload.totalventasPending;
                state.contador.rejected = action.payload.totalventasRejected;
                state.contador.total = action.payload.totalventas;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // GET Plataforms
            .addCase(getPlataforms.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getPlataforms.fulfilled, (state, action) => {
                state.plataforms = action.payload.plataforms
                state.isMutation.loading = false;
            })
            .addCase(getPlataforms.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET SMM services
            .addCase(getServices.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getServices.fulfilled, (state, action) => {
                state.services = action.payload.services;
                state.isMutation.loading = false;
            })
            .addCase(getServices.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET SMM services API By ID
            .addCase(getServiceApiById.pending, (state) => {
                state.isMutation.aloading = true;
            })
            .addCase(getServiceApiById.fulfilled, (state, action) => {
                state.serviceAPI = action.payload.service;
                state.isMutation.aloading = false;
            })
            .addCase(getServiceApiById.rejected, (state, action) => {
                state.isMutation.aloading = false;
            })

            // UPDATE PLATAFORM

            .addCase(updatePlataform.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updatePlataform.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.plataforms.map((plat) => {
                    if (plat._id === action.payload.newData._id) {
                        plat = action.payload.newData
                    }
                    return plat
                })
                state.plataforms = newState
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updatePlataform.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE SMM SERVICE

            .addCase(updateService.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.services.map((serv) => {
                    if (serv._id === action.payload.newData._id) {
                        serv = action.payload.newData
                    }
                    return serv
                })
                state.services = newState;
                state.isMutation.extra.service = action.payload.newData;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateService.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // CHANGE STATE

            .addCase(updateSocialOrder.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateSocialOrder.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        return {
                            ...venta,
                            state: action.payload.newData.state,
                            observation: action.payload.newData.observation,
                            remains: action.payload.newData.remains,
                            link: action.payload.newData.link,
                            orderId: action.payload.newData.orderId
                        }
                    }
                    return venta
                })
                state.ventasByPage = newState;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateSocialOrder.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })


            // CREATE MESSAGE REPORT

            .addCase(createMessageReport.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(createMessageReport.fulfilled, (state, action) => {
                //state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        return {
                            ...venta,
                            chat: action.payload.newData.chat
                        }
                    }
                    return venta
                })
                state.ventasByPage = newState;
                state.isMutation.extra.chat = action.payload.newData.chat;
                state.isMutation.extra.type = action.payload.type
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(createMessageReport.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // DELETE PLATAFORM

            .addCase(deletePlataform.pending, (state) => {
                state.isMutation.dloading = true;
            })
            .addCase(deletePlataform.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.plataforms = state.plataforms.filter(fil => fil._id !== action.payload.id)
                state.isMutation.dloading = false;
                state.isMutation.deleted = true;
            })

            .addCase(deletePlataform.rejected, (state, action) => {
                state.isMutation.dloading = false;
            })

            // DELETE SMM SERVICE

            .addCase(deleteService.pending, (state) => {
                state.isMutation.dloading = true;
            })
            .addCase(deleteService.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.services = state.services.filter(fil => fil._id !== action.payload.id)
                state.isMutation.dloading = false;
                state.isMutation.deleted = true;

            })
            .addCase(deleteService.rejected, (state, action) => {
                state.isMutation.dloading = false;
            })
    },
});

export const { UPDATE_SOCIAL_FROM_SOCKET, UPDATE_LAST10_OF_PLATAFORM, SET_TO_DEFAULT_RESPONSE, SET_PLATAFORM_SERVICE } = socialSlice.actions;

export default socialSlice.reducer;

