import { useState } from "react"
import AdminNav from "../../components/AdminNav";
import Sidebar from "../../components/Sidebar";
import { ChakraProvider } from "@chakra-ui/react";
const Wrapper = ({ children, socket, configuration, loadingconfiguration }) => {
    const [side, setSide] = useState('-translate-x-full')
    const openCloseSidebar = () => {
        if (side === "-translate-x-full") {
            setSide("")
        } else {
            setSide("-translate-x-full");
        }
    }
    const closeSidebar = () => {
        setSide('-translate-x-full');
    }
    return (
        <>
            <Sidebar side={side} closeSidebar={closeSidebar} />
            <div className="ml-0 md:ml-0 lg:ml-72 bg-brand-200 dark:bg-brand-950 min-h-screen px-3 md:px-3 lg:px-16">
                <AdminNav openCloseSidebar={openCloseSidebar} />
                <section className="relative min-h-screen overflow-hidden">
                    <div className="relative bg-brand-200 dark:bg-brand-950 py-5 rounded-xl mt-14 lg:px-1">
                        {children}
                    </div>
                </section>
            </div>
        </>
    )
}
export default Wrapper;