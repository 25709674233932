import AccountList from "../../components/home/AccountList";
import { IoCloseCircle, IoCloseSharp, IoTime } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdEventNote, MdPayment, MdVerified } from "react-icons/md";
import { IconButton, Pagination, PaginationItem, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import debounce from 'lodash.debounce';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FaFrown, FaShippingFast } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FaBan, FaChessKing, FaCoins, FaRocket, FaSquareCheck } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { SET_TO_DEFAULT_RESPONSE, getByPageByUserId } from "../../store/features/slices/paymentSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import SpinnerData from "../../components/SpinnerData";

const PaymentsHistory = ({ }) => {

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);

  const {
    userToken,
    user
  } = useSelector((state) => state.userReducer);

  const {
    paymentsByPage,
    isMutation,
    totalFilter,
    total
  } = useSelector((state) => state.paymentReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }

    const queryOptions = {
      id: userToken && user?.id,
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getByPageByUserId(query))
  }, [dispatch, searchTerm.trim(), currentPage, limit, isMutation.success]);

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    setCurrentPage(1)
    document.getElementById("search").value = "";
  };

  return (
    <AccountList>

      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-b-xl">
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-full">
            <FaCoins size={65} style={{ marginRight: 10 }} />
            Mis recargas
            <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-brand-200 dark:bg-brand-800 text-brand-900 dark:text-brand-200 rounded-full">
              {!isMutation.loading && total}
            </div>
          </div>
        </div>
        <div className="mt-3 !w-full md:!w-[450px] md:!max-w-[450px]">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar recargas por método, id o monto...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[160px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="w-full flex items-center justify-center md:justify-end text-brand-800 dark:text-brand-200 mt-0 h-10">
          {!isMutation.loading ?
            <>
              <div className="hidden md:block">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="large"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-white dark:bg-brand-900"
                  />
                </Stack>
              </div>
              <div className="block md:hidden">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-white dark:bg-brand-900"
                  />
                </Stack>
              </div>
            </> : <Spinner />
          }
        </div>
      </div>

      <div className="relative w-full mt-[275px]">

        {
          !isMutation.loading ? paymentsByPage.length > 0 ? (
            <div>
              {
                paymentsByPage.map((payment) => {

                  return (
                    <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-12 rounded-lg animation`} key={payment._id}>
                      <label className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                        <span className="text-[12px]">{payment.payment_id}</span>
                      </label>
                      <div className="grid grid-wrap w-full md:w-full lg:w-4/12">
                        <div className="flex inline-flex items-center">
                          <LazyLoadImage
                            className="w-14 h-14 z-10 rounded-full flex inline-flex object-cover"
                            alt={`No image ${payment.method}`}
                            effect="opacity"
                            src={`/images/paymethods/${payment.method}.webp`}
                          />
                          <div className="grid gap-1 self-center">
                            <div className="ml-2 text-xl font-semibold capitalize">{payment.method}</div>
                            <div className="ml-2 text-xs truncate ...">{setUnixToTimeFormat('created_at', payment.created_at)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-8/12 mt-5 md:mt-4 lg:mt-0">

                        <div className="md:w-4/12 w-full grid">
                          <div className="flex items-center justify-center uppercase text-sm">Origen</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              {payment.payment_type === 'admin' ?
                                <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaChessKing size={24} className="mr-2" />ADMINISTRADOR</div>
                                :
                                <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><MdPayment size={24} className="mr-2" />PASARELA</div>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="md:w-3/12 w-full grid">
                          <div className="flex items-center justify-center uppercase text-sm">Monto</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              {payment.status === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                              {payment.status === "pending" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                              {payment.status === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                              {payment.status === "canceled" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{currencyFormat(payment.total_recharge)}</div>}
                            </div>
                          </div>
                        </div>

                        <div className="md:w-4/12 w-full grid">
                          <div className="flex items-center justify-center uppercase text-sm">Estado</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              {payment.status === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                              {payment.status === "pending" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaRocket size={24} className="mr-2" />Pendiente</div>}
                              {payment.status === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><FaBan size={24} className="mr-2" />Rechazado</div>}
                              {payment.status === "canceled" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoCloseCircle size={24} className="mr-2" />Cancelado</div>}
                            </div>
                          </div>
                        </div>

                        <div className="md:w-4/12 w-full grid">
                          <div className="flex items-center justify-center uppercase text-sm">Observaciones</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2 text-xs"><MdEventNote size={24} className="mr-2" />
                                {payment.observations}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                })
              }
            </div>) : (<div className="alert-danger font-semibold mt-[275px]">Sin resultados en la búsqueda! o no existen recargas registrados</div>) : (<SpinnerData>Cargando pedidos, por favor espere...</SpinnerData>)
        }

      </div>

    </AccountList >
  );
};

export default PaymentsHistory;
