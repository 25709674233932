import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import historyService from "../services/historyService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, deleted: false, dloading: false, uloading: false, extra: null, sloading: false },
    contador: { total: 0, loading: false },
    historiesByPage: [],
    last10Ventas: [],
    histories: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
};

export const getBypage = createAsyncThunk(
    "history/getBypage/historiesbypage",
    async (query, thunkAPI) => {
        try {
            return await historyService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getLast10Ventas = createAsyncThunk(
    "history/getLast10Ventas/last10ventas",
    async (_, thunkAPI) => {
        try {
            return await historyService.getLast10Ventas();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteAll = createAsyncThunk(
    "history/deleteAll/history",
    async (_, thunkAPI) => {
        try {
            return await historyService.deleteAll();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, deleted: false, dloading: false, uloading: false, extra: null, sloading: false }
        },
        UPDATE_LAST10_VENTAS(state, action) {
            const array = state.last10Ventas;
            if (array.length >= 10) {
                array.pop();
            }
            array.unshift(action.payload);
            state.last10Ventas = array;
        }
    },
    extraReducers: (builder) => {
        builder

            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.historiesByPage = action.payload.histories;
                state.totalFilter = action.payload.totalhistoriesFilter;
                state.contador.total = action.payload.totalhistories;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BYPAGE

            .addCase(getLast10Ventas.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getLast10Ventas.fulfilled, (state, action) => {
                state.last10Ventas = action.payload.last10Ventas;
                state.isMutation.loading = false;
            })
            .addCase(getLast10Ventas.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // DELETE ALL

            .addCase(deleteAll.pending, (state) => {
                state.isMutation.dloading = true;
            })
            .addCase(deleteAll.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.historiesByPage = []
                state.totalFilter = 0
                state.totalPages = 1
                state.currentPage = 1
                state.contador.total = 0
                state.isMutation.dloading = false;
                state.isMutation.deleted = true;
            })

            .addCase(deleteAll.rejected, (state, action) => {
                state.isMutation.dloading = false;
            })
    },
});

export const { SET_TO_DEFAULT_RESPONSE, UPDATE_LAST10_VENTAS } = historySlice.actions;

export default historySlice.reducer;

