
import { Pagination, Autoplay, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import moment from 'moment';
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import { MdSell } from 'react-icons/md';

const LastVentas = ({ socket }) => {

  const [curTime, SetCurTime] = useState(null)

  const { isMutation, last10Ventas } = useSelector((state) => state.historyReducer);

  useEffect(() => {
    const interval = setInterval(async () => {
      SetCurTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  return (
    <>
      <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full 
       text-brand-900 dark:text-brand-100
        mb-3 mt-4 md:mt0 text-center text-xl md:text-3xl pt-5 pb-5 md:pt-14 md:pb-14 contrast-[125%]">
        ULTIMAS VENTAS REALIZADAS
      </div>
      <div className="flex flex-wrap w-full">

        <div className="flex flex-wrap w-full">
          <Swiper
            modules={[Virtual, Pagination, Autoplay]}
            pagination={{
              type: 'fraction'
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            slidesPerView={3}
            breakpoints={{
              320: { slidesPerView: 1.5, spaceBetween: 15 },
              640: { slidesPerView: 2.5, spaceBetween: 15 },
              1024: { slidesPerView: 3.5, spaceBetween: 15 },
            }}
            virtual
            className="w-full h-[180px] md:h-[170px] lg:h-[130px]"
          >

            {!isMutation.loading && last10Ventas.map((venta, index) => {
              var action = ""
              if (venta.action === 'New Streaming') {
                action = 'ah comprado un servicio de'
              } else if (venta.action === 'Renew Streaming') {
                action = 'ah renovado su servicio de'
              } else if (venta.action === 'New GiftCard') {
                action = 'ah comprado un servicio de'
              } else {
                action = 'ah comprado un SMM servicio de '
              }

              return (
                <SwiperSlide
                  className="w-full overflow-hidden relative"
                  key={index}
                  virtualIndex={index}
                >
                  <div key={venta._id} className="relative grid grid-cols-4 h-[180px] md:h-[170px] lg:h-[130px] bg-brand-200 dark:bg-brand-900 rounded-xl p-4">
                    <MdSell size={150} className="absolute opacity-10 z-10 m-auto right-5 top-8" />
                    <div className='z-20 col-span-1 flex lg:justify-center items-center'>
                      <div className='relative max-w-[55px] min-w-[55px] max-h-[55px] min-h-[55px] md:max-w-[55px] md:min-w-[55px] md:max-h-[55px] md:min-h-[55px] lg:max-w-[65px] lg:min-w-[65px] lg:max-h-[65px] lg:min-h-[65px]'>
                        <div className='w-full absolute'>
                          <LazyLoadImage
                            className="object-cover max-w-[55px] min-w-[55px] max-h-[55px] min-h-[55px] md:max-w-[55px] md:min-w-[55px] md:max-h-[55px] md:min-h-[55px] lg:max-w-[65px] lg:min-w-[65px] lg:max-h-[65px] lg:min-h-[65px] rounded-full m-auto"
                            alt={`No image ${venta.smmservicio ? venta.smmservicio?.name : venta.subcategory && venta.subcategory?.name}`}
                            effect="opacity"
                            src={`/images/logos/${venta.plataform ? venta.plataform?.sublogo : venta.category && venta.category?.sublogo}`}
                          />
                        </div>
                        <div className='w-full absolute -bottom-2 right-2'>
                          <LazyLoadImage
                            className="object-cover m-auto max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] rounded-full m-auto border-2 border-brand-400 dark:border-brand-700"
                            alt={`No image user`}
                            effect="opacity"
                            src={`/images/photos/${venta.user?.photo}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="z-20 col-span-3 pl-2 md:pl-2 lg:pl-0 text-sm font-light max-h-[135px] min-h-[135px] md:max-h-[125px] md:min-h-[125px] lg:max-h-[85px] lg:min-h-[85px]">
                      El usuario {venta.user?.email.replace(/[a-z0-9\-_.]+@/ig, (c) => c.substr(0, 3) + c.split('').slice(3, -1).map(v => '*').join('') + '@')} {action} {venta.smmservicio ? venta.smmservicio?.name : venta.subcategory && venta.subcategory?.name}
                    </div>
                    <div className="z-20 col-span-4 text-center text-xs h-[25px] flex items-center justify-center">
                      {moment(venta.created_at).fromNow()}
                    </div>
                  </div>
                </SwiperSlide>
              )
            })
            }

          </Swiper>
        </div>
      </div>
    </>
  )
};

export default LastVentas;
