import { configureStore } from "@reduxjs/toolkit";

import categoryReducer from "./features/slices/categorySlice";
import userReducer from "./features/slices/userSlice";
import cuentaReducer from "./features/slices/cuentaSlice";
import ventaReducer from "./features/slices/ventaSlice";
import notificationReducer from "./features/slices/notificationSlice";
import paymentReducer from "./features/slices/paymentSlice";
import giftcardReducer from "./features/slices/giftcardSlice";
import historyReducer from "./features/slices/historySlice";
import socialReducer from "./features/slices/socialSlice";
import gcventaReducer from "./features/slices/gcventaSlice";
import searchReducer from "./features/slices/searchSlice";
import dashboardReducer from "./features/slices/dashboardSlice";

const Store = configureStore({
  reducer: {
    userReducer,
    categoryReducer,
    cuentaReducer,
    ventaReducer,
    notificationReducer,
    paymentReducer,
    giftcardReducer,
    historyReducer,
    socialReducer,
    gcventaReducer,
    searchReducer,
    dashboardReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([]),
});
export default Store;
