import React from 'react';

const SparklesBackground = () => {
    return (
        <div className="fixed w-full h-full top-0 left-0 overflow-hidden">
            {[...Array(100)].map((_, i) => (
                <div
                    key={i}
                    className="sparkle"
                    style={{
                        animationDelay: `${Math.random() * 10}s`,
                        left: `${Math.random() * 100}vw`,
                    }}>
                </div>
            ))}
        </div>
    );
};

export default SparklesBackground;
