import React from 'react';
import { useSelector } from 'react-redux';

const MaintenancePage = () => {
    const { configuration } = useSelector((state) => state.userReducer);
    return (
            <div className="relative m-auto w-full h-screen">
                <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                    <header className="w-full bg-gradient-to-r from-[#00008b] backdrop-blur-lg p-4 text-white text-center">
                        <h1 className="text-2xl font-bold">Sitio Web en Mantenimiento</h1>
                    </header>
                    <main className="flex flex-col items-center justify-center flex-1 p-4">
                        <img
                            src="../images/maintenance.svg"
                            alt="Mantenimiento"
                            className="mb-4"
                        />
                        <p className="text-lg text-gray-700">
                            Nuestro sitio web está actualmente en mantenimiento. Por favor, vuelva más tarde.
                        </p>
                    </main>
                    <footer className="w-full bg-gradient-to-r from-[#00008b] backdrop-blur-lg p-4 text-white text-center">
                        Copyright © 2023
                        {new Date().getFullYear() > 2020 && (" - " + new Date().getFullYear() + " ")} {configuration?.name}
                    </footer>
                </div>
        </div>
    );
};

export default MaintenancePage