import { Button, IconButton, Pagination, PaginationItem, Stack, Tooltip } from "@mui/material";
import { IoBan, IoCloseSharp } from "react-icons/io5";
import AccountList from "../../components/home/AccountList";
import { FaClipboard, FaShippingFast } from 'react-icons/fa';
import { useMemo, useState, useEffect } from 'react';
import { currencyFormat, message, setUnixToTimeFormat } from '../../utils/Funciones.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import SpinnerData from '../../components/SpinnerData';
import CopyToClipboard from 'react-copy-to-clipboard';
import { GiShoppingBag } from 'react-icons/gi';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { SET_TO_DEFAULT_RESPONSE, getByPageByUserId } from '../../store/features/slices/gcventaSlice';
import { uriSerialized } from '../../utils/uriSerialized';
import { FaEye, FaKey, FaMoneyBillTransfer, FaRulerVertical, FaSquareCheck } from 'react-icons/fa6';
import Modal from '../../components/modal/Modal';

const MyGiftCards = ({ }) => {
   const dispatch = useDispatch();
   const [curTime, SetCurTime] = useState(null)
   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [limit, setLimit] = useState(30);
   const [verdetalles, SetVerDetalles] = useState({
      open: false,
      ventaId: "",
      categoryname: "",
      subcategoryname: "",
      subcategorylogo: "",
      gcdata: [],
      copied: false
   })

   const { userToken, user } = useSelector((state) => state.userReducer);

   const {
      ventasByPage,
      isMutation,
      totalFilter,
      total
   } = useSelector((state) => state.gcventaReducer);

   useEffect(() => {
      if (isMutation.success) {
         message(isMutation.message, 'success', 5)
         dispatch(SET_TO_DEFAULT_RESPONSE())
      }

      const queryOptions = {
         id: userToken && user?.id,
         page: currentPage,
         limit: limit,
         search: searchTerm.trim(),
      }
      const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
      dispatch(getByPageByUserId(query))
   }, [dispatch, searchTerm.trim(), currentPage, limit, isMutation.success]);


   useEffect(() => {
      window.scrollTo(0, 0);
      const interval = setInterval(() => {
         SetCurTime(new Date().toLocaleString());
      }, 1000);
      return () => clearInterval(interval);
   }, [])

   const handleOpenDetalles = (venta) => {
      SetVerDetalles({ ...verdetalles, open: true, ventaId: venta.ventaId, categoryname: venta.category?.name, subcategoryname: venta.subcategory?.name, subcategorylogo: venta.subcategory?.logo, gcdata: venta.gcdata })
   }

   const onCopy = (text) => {
      SetVerDetalles({ ...verdetalles, copied: true });
      message(text, 'success', 2)
   }

   const handleChangePage = (e, p) => {
      setCurrentPage(p);
   };

   const changeHandleSearch = (event) => {
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

   const onChangeSearchTermClear = e => {
      setSearchTerm("");
      setCurrentPage(1)
      document.getElementById("search").value = "";
   };

   return (
      <AccountList>

         {/* HEADER PAGES */}
         <div className="fixed mt-8 p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-b-xl">
            <div className="w-full h-14 text-lg md:text-2xl flex items-center">
               <div className="flex items-center w-full">
                  <GiShoppingBag size={65} style={{ marginRight: 10 }} />
                  Mis trajetas de regalo
                  <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-brand-200 dark:bg-brand-800 text-brand-900 dark:text-brand-200 rounded-full">
                     {!isMutation.loading && total}
                  </div>
               </div>
            </div>
            <div className="mt-3 !w-full md:!w-[450px] md:!max-w-[450px]">
               <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
                  <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
                     <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
                  </InputLeftElement>
                  <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar pedidos por categoría...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
                  <InputRightElement className="!mt-[8px] !mr-1">
                     <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                        <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
                     </IconButton>
                  </InputRightElement>
               </InputGroup>
            </div>
         </div>
         <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[140px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
            <div className="w-full flex items-center justify-center md:justify-end text-brand-800 dark:text-brand-200 mt-0 h-10">
               <div className="hidden md:block">
                  <Stack spacing={2}>
                     <Pagination
                        count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="text"
                        color="primary"
                        shape="rounded"
                        size="large"
                        renderItem={(item) => (
                           <PaginationItem
                              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                              {...item}
                           />
                        )}
                        className="bg-white dark:bg-brand-900"
                     />
                  </Stack>
               </div>
               <div className="block md:hidden">
                  <Stack spacing={2}>
                     <Pagination
                        count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="text"
                        color="primary"
                        shape="rounded"
                        size="small"
                        renderItem={(item) => (
                           <PaginationItem
                              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                              {...item}
                           />
                        )}
                        className="bg-white dark:bg-brand-900"
                     />
                  </Stack>
               </div>
            </div>
         </div>
         <div className='w-full'>
            <Modal
               open={verdetalles.open}
               onClose={() => SetVerDetalles({ ...verdetalles, open: false, ventaId: "", categoryname: "", subcategoryname: "", subcategorylogo: "", username: "", email: "", gcdata: [] })}
               headerTitle={`Detalles del pedido`}
               size='base'
            >
               <div className="relative w-full">
                  <span className="p-2 text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-lg bg-brand-200 dark:bg-brand-800">
                     {verdetalles.ventaId}
                  </span>
                  <div className="w-full text-brand-900 dark:text-brand-200 mt-3">
                     <div className="grid grid-wrap w-full md:w-9/12">
                        <div className="flex inline-flex items-center">
                           <LazyLoadImage
                              className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                              alt={`No image ${verdetalles.subcategoryname}`}
                              effect="opacity"
                              src={`/images/logos/${verdetalles.subcategorylogo}`}
                           />
                           <div className="grid self-center">
                              <div className="ml-2 text-sm font-semibold">{verdetalles.categoryname}</div>
                              <div className="ml-2 text-sm font-semibold">{verdetalles.subcategoryname}</div>
                           </div>
                        </div>
                     </div>
                     <div className='bg-brand-50 dark:bg-brand-950 w-full text-center mt-5'>
                        <div className='text-lg bg-brand-300 dark:bg-brand-800 p-5 w-full text-center flex items-center justify-center mb-2'>Tarjetas de regalo <div className='bg-brand-200 dark:bg-brand-900 p-2 rounded-full w-8 h-8 ml-2 flex items-center justify-center'>{verdetalles.gcdata.length}</div></div>
                        {
                           verdetalles.gcdata.map((gc, index) => {
                              return (
                                 <div className="relative flex w-full flex-wrap bg-brand-200 dark:bg-brand-900 p-3 mb-4">
                                    <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-[50px] grid text-xl self-center">
                                       {index + 1}
                                    </div>
                                    <div className="w-full md:w-[calc(50%-50px)] lg:w-[calc(50%-50px)] grid">
                                       <div className="flex items-center justify-center uppercase text-sm">Precio unitario</div>
                                       <div className="flex items-center justify-center">
                                          <div className="flex inline-flex items-center">
                                             <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaMoneyBillTransfer size={24} className="mr-2" />{currencyFormat(gc.priceunit)}</div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="w-full md:w-6/12 lg:w-6/12 grid mt-3 md:mt-0 lg:mt-0">
                                       <div className="flex items-center justify-center uppercase text-sm">Código</div>
                                       <div className="flex items-center justify-center">
                                          <div className="flex inline-flex items-center justify-center">
                                             <div class="w-full text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2">
                                                <FaKey size={24} className="mr-2 w-[35px] min-w-[35px]" />
                                                <div className="w-[calc(100%-60px)] text-base">{(gc.gcId?.code).substr(0, gc.gcId?.code.length - 4)}...</div>
                                                <div className="ml-3 w-[30px] flex justify-end">
                                                   <Tooltip title="copiar" arrow={true}>
                                                      <div>
                                                         <CopyToClipboard text={gc.gcId?.code} onCopy={() => onCopy('código copiado')}>
                                                            <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                                         </CopyToClipboard>
                                                      </div>
                                                   </Tooltip>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
            </Modal>
         </div>

         <div className="relative w-full mt-[260px]">
            {
               !isMutation.loading ? ventasByPage.length > 0 ? (
                  <div>
                     {
                        ventasByPage.map((venta) => {
                           return (
                              <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-lg animation`} key={venta.ventaId}>
                                 <label className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                                    <span className="text-[12px]">{venta.ventaId}</span>
                                 </label>
                                 <div className="flex flex-wrap items-center w-full md:w-full lg:w-4/12">
                                    <div className="flex inline-flex items-center">
                                       <LazyLoadImage
                                          className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                                          alt={`No image ${venta.subcategory?.name}`}
                                          effect="opacity"
                                          src={`/images/logos/${venta.subcategory?.logo}`}
                                       />
                                    </div>
                                    <div className="w-[calc(100%-56px)]">
                                       <div className="ml-2 text-sm uppercase font-bold truncate ...">{venta.subcategory?.name}<span className="text-xs ml-2 opacity-60">({venta.category?.name})</span></div>
                                       <div className="ml-2 text-xs truncate ...">{setUnixToTimeFormat('created_at', venta.created_at)}</div>
                                    </div>
                                 </div>

                                 <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-6/12 mt-5 md:mt-4 lg:mt-0">
                                    <div className="md:w-4/12 w-full grid">
                                       <div className="flex items-center justify-center uppercase text-sm">Cantidad</div>
                                       <div className="flex items-center justify-center">
                                          <div className="flex inline-flex items-center">
                                             <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2"><FaRulerVertical size={24} className="mr-2" />{venta.quantity}</div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="md:w-4/12 w-full grid">
                                       <div className="flex items-center justify-center uppercase text-sm">Costo total</div>
                                       <div className="flex items-center justify-center">
                                          <div className="flex inline-flex items-center">
                                             <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaMoneyBillTransfer size={24} className="mr-2" />{currencyFormat(venta.totalamount)}</div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="md:w-4/12 w-full grid">
                                       <div className="flex items-center justify-center uppercase text-sm">Estado</div>
                                       <div className="flex items-center justify-center">
                                          {venta.state === "Completed" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                                          {venta.state === "Pending" && <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><FaShippingFast size={24} className="mr-2" />Pendiente</div>}
                                          {venta.state === "Rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoBan size={24} className="mr-2" />Rechazado</div>}
                                       </div>

                                       {venta.observations !== "" &&
                                          <span className='text-xs text-center'>
                                             {venta.observations}
                                          </span>
                                       }

                                    </div>
                                 </div>

                                 <div className="flex items-center gap-2 justify-end w-full md:w-full lg:md:w-2/12 mt-5 md:mt-5 lg:mt-0">
                                    <Tooltip title="ver detalles" arrow={true}>
                                       <Button
                                          startIcon={<FaEye />}
                                          sx={{
                                             "&.Mui-disabled": {
                                                background: "#525252",
                                                color: "#a3a3a3"
                                             }, "&:hover": {
                                                background: "#1e3a8a"
                                             }, background: '#1d4ed8'
                                          }}

                                          onClick={() => handleOpenDetalles(venta)} variant="contained" color="primary" disabled={(venta.state === "Rejected" || venta.state === "Pending") ? true : false}>
                                          Detalles
                                       </Button>
                                    </Tooltip>
                                 </div>
                              </div>
                           )
                        })
                     }
                  </div>) : (<div className="alert-danger font-semibold mt-[316px]">Sin resultados en la búsqueda! o no existen pedidos registrados</div>) : (<SpinnerData />)
            }

         </div>
      </AccountList >
   )
}
export default MyGiftCards;