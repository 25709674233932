import { useState } from "react";
import { BsCashStack, BsPlusCircleDotted, BsServer } from "react-icons/bs";
import { FaAngleDown, FaAngleRight, FaAngleUp } from "react-icons/fa";
import { IoBag, IoBagCheck, IoChevronDown, IoChevronUp, IoEllipse, IoGiftSharp, IoHome, IoLogoMicrosoft, IoPodium, IoReceipt, IoServer, IoShareSocialSharp } from "react-icons/io5";
import { MdAddReaction, MdAllInbox, MdCardGiftcard, MdContactMail, MdDashboard, MdHistory, MdManageAccounts, MdOutlineCardGiftcard, MdSettingsApplications, MdSupervisorAccount } from "react-icons/md";
import { SiServerfault } from "react-icons/si";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Sidebar = ({ side, closeSidebar }) => {

  const { configuration } = useSelector((state) => state.userReducer);
  const [OpenCloseManageAccounts, SetOpenCloseManageAccounts] = useState(false)
  const [OpenCloseManageCategories, SetOpenCloseManageCategories] = useState(false)
  const [OpenCloseManageUsers, SetOpenCloseManageUsers] = useState(false)
  const [OpenCloseManagePedidos, SetOpenCloseManagePedidos] = useState(false)
  const [OpenCloseManageServer, SetOpenCloseManageServer] = useState(false)
  const [OpenCloseManageGiftCard, SetOpenCloseManageGiftCard] = useState(false)
  const [OpenCloseManagePayments, SetOpenCloseManagePayments] = useState(false)
  const [OpenCloseManagePlataforms, SetOpenCloseManagePlataforms] = useState(false)

  const HandleOpenCloseManageAccounts = (e) => {
    SetOpenCloseManageAccounts(!OpenCloseManageAccounts)
  }

  const HandleOpenCloseManageCategories = (e) => {
    SetOpenCloseManageCategories(!OpenCloseManageCategories)
  }

  const HandleOpenCloseManageUsers = (e) => {
    SetOpenCloseManageUsers(!OpenCloseManageUsers)
  }

  const HandleOpenCloseManagePedidos = (e) => {
    SetOpenCloseManagePedidos(!OpenCloseManagePedidos)
  }

  const HandleOpenCloseManageServer = (e) => {
    SetOpenCloseManageServer(!OpenCloseManageServer)
  }

  const HandleOpenCloseManageGiftCard = (e) => {
    SetOpenCloseManageGiftCard(!OpenCloseManageGiftCard)
  }

  const HandleOpenCloseManagePayments = (e) => {
    SetOpenCloseManagePayments(!OpenCloseManagePayments)
  }

  const HandleOpenCloseManagePlataforms = (e) => {
    SetOpenCloseManagePlataforms(!OpenCloseManagePlataforms)
  }

  return (
    <div>
      {
        side === "" && <div className="bg-brand-50 bg-opacity-30 fixed inset-0 z-40" onClick={closeSidebar}></div>
      }

      <aside id="logo-sidebar"
        class={`fixed left-0 top-0 z-50 md:z-40 w-72 h-screen transition-transform duration-500 bg-white dark:bg-brand-900 backdrop-blur-xl lg:translate-x-0 ${side} `} aria-label="Sidebar">
        <div class="relative h-full px-3 py-3 pb-4 overflow-y-auto">
          <div className="sticky z-30 bg-lightPrimary dark:bg-darkPrimary top-0 left-0 flex items-center w-full h-24">
            <div className="w-full flex items-center justify-center cursor-pointer w-[165px] h-24">
              {
                <Link to="/admin/dashboard" className="">
                  <LazyLoadImage
                    className="w-[150px] h-[60px]"
                    alt={'No image logo'}
                    src={`/images/logos/${configuration?.logo}`}
                  />
                </Link>
              }
            </div>
          </div>
          <ul class="space-y-2 mt-4">
            <li>
              <Link to="/admin/dashboard" className="capitalize flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg p-2 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><MdDashboard className="flex-shrink-0 w-6 h-6" /></div><span>Dashboard</span>
              </Link>
            </li>
            <li>
              <button onClick={HandleOpenCloseManageAccounts} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><MdAllInbox className="flex-shrink-0 w-6 h-6" /></div><span>Gestion Cuentas</span>
                {
                  OpenCloseManageAccounts ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageAccounts ? `h-[105px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/cuentas" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Cuentas</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-cuenta" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mb-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Nueva Cuenta</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManageCategories} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><IoServer className="flex-shrink-0 w-6 h-6" /></div><span>Gestion Categorías</span>
                {
                  OpenCloseManageCategories ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }

              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageCategories ? `h-[153px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/categories" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">C. Streaming</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/categoriesGC" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">C. Tarjetas de regalo</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-category" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mb-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Nueva Categoría</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button onClick={HandleOpenCloseManageGiftCard} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><MdOutlineCardGiftcard className="flex-shrink-0 w-6 h-6" /></div><span>Gestion T. Regalo</span>
                {
                  OpenCloseManageGiftCard ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageGiftCard ? `h-[105px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/giftcards" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Tarjetas de Regalo</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-giftcard" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mb-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Nueva T. regalo</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button onClick={HandleOpenCloseManageUsers} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><MdManageAccounts className="flex-shrink-0 w-6 h-6" /></div><span>Gestion Usuarios</span>
                {
                  OpenCloseManageUsers ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageUsers ? `h-[105px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/users" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Usuarios</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-user" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mb-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Nuevo Usuario</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManagePedidos} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><IoBag className="flex-shrink-0 w-6 h-6" /></div><span>Gestion Pedidos</span>
                {
                  OpenCloseManagePedidos ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManagePedidos ? `h-[105px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/streamingorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Pedidos Streaming</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/giftcardsorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mb-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Pedidos GiftCards</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManagePayments} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><BsCashStack className="flex-shrink-0 w-6 h-6" /></div><span>Gestion Recargas</span>
                {
                  OpenCloseManagePayments ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManagePayments ? `h-[56px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/payments" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary my-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Recargas</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/admin/history" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg p-2 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><MdHistory className="flex-shrink-0 w-6 h-6" /></div><span>Historial</span>
              </Link>
            </li>

            <li>
              <button onClick={HandleOpenCloseManagePlataforms} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><IoShareSocialSharp className="flex-shrink-0 w-6 h-6" /></div><span>SMM Servicios</span>
                {
                  OpenCloseManagePlataforms ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManagePlataforms ? `h-[153px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/create-social-service" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Crear Servicio</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/socialplataforms" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Plataformas</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/socialorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mb-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Pedidos</span>
                  </Link>
                </li>
              </ul>
            </li>


            <li>
              <button onClick={HandleOpenCloseManageServer} type="button" class="relative flex items-center w-full p-2 text-sm font-bold text-brand-700 dark:text-brand-300 rounded-lg 
               transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary pt-2 pb-2" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <div className="bg-white dark:bg-brand-900 shadow-lg rounded-xl p-2 flex items-center justify-center mr-2"><BsServer className="flex-shrink-0 w-6 h-6" /></div><span>Gestionar Servidor</span>
                {
                  OpenCloseManageServer ?
                    <FaAngleRight size={16} className="absolute right-1 rotate-90 transition-transform ease-in-out duration-500" />
                    :
                    <FaAngleRight size={16} className="absolute right-1 transition-transform ease-in-out duration-500" />
                }
              </button>
              <ul id="dropdown-example" className={`${OpenCloseManageServer ? `h-[56px]` : 'h-[0px]'} block transition-all ease-in-out duration-500 overflow-hidden`}>
                <li>
                  <Link to="/admin/configuration" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-brand-700 dark:text-brand-300 rounded-lg pl-5 transition-colors duration-500 ease-in-out hover:bg-lightPrimary/10 dark:hover:bg-darkPrimary/10 hover:text-lightPrimary dark:hover:text-darkPrimary mt-2">
                    <IoEllipse size={14} className="mr-2 h-9" /><span className="">Configurar Servidor</span>
                  </Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </aside>
    </div>
  );
};
export default Sidebar;
