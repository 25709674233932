import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import categoryService from "../services/categoryService";
import toast, { } from "react-hot-toast";
import { message } from "../../../utils/Funciones";

const initialState = {
    responseSuccessCreate: null,
    responseSuccessGet: null,
    responseSuccessUpdateCategory: null,
    responseSuccessUpdateSubCategory: null,
    responseSuccessDeleteCategory: null,
    responseSuccessDeleteSubCategory: null,
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, dloading: false, extra: {}, sloading: false },
    categories: [],
    emails: [],
    subcategories: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalStoreValue: 0
};

export const createCategory = createAsyncThunk(
    "category/createCategory",
    async (formData, thunkAPI) => {
        try {
            return await categoryService.createCategory(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getCategories = createAsyncThunk(
    "category/getCategories",
    async (_, thunkAPI) => {
        try {
            return await categoryService.getCategories();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getSubCategories = createAsyncThunk(
    "category/getSubCategories/subcategories",
    async (_, thunkAPI) => {
        try {
            return await categoryService.getSubCategories();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateCategory = createAsyncThunk(
    "category/updateCategory/category",
    async (formData, thunkAPI) => {
        try {
            return await categoryService.updateCategory(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateSubCategory = createAsyncThunk(
    "category/updateSubCategory/subcategory",
    async (formData, thunkAPI) => {
        try {
            return await categoryService.updateSubCategory(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteCategory = createAsyncThunk(
    "category/deleteCategory/category",
    async (id, thunkAPI) => {
        try {
            return await categoryService.deleteCategory(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteSubCategory = createAsyncThunk(
    "category/deleteSubCategory/subcategory",
    async (id, thunkAPI) => {
        try {
            return await categoryService.deleteSubCategory(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, dloading: false, extra: {}, sloading: false }
            state.responseSuccessCreate = null
            state.responseSuccessGet = null
            state.responseSuccessUpdateCategory = null
            state.responseSuccessUpdateSubCategory = null
            state.responseSuccessDeleteCategory = null
            state.responseSuccessDeleteSubCategory = null
        },

        SET_CATEGORY_SUBCATEGORY(state, action) {
            state.categories = action.payload.categories;
            state.subcategories = action.payload.subcategories;
        },

        NEW_CATEGORY(state, action) {
            action.payload.history = []
            return {
                ...state,
                categories: [...state.categories, action.payload]
            };
        },

        NEW_SUBCATEGORY(state, action) {
            return {
                ...state,
                subcategories: [...state.subcategories, action.payload]
            };
        },

        CALC_STORE_CATEGORY_VALUE(state, action) {
            const categories = action.payload;
            const array = [];
            categories.map((item) => {
                const { price, quantity } = item;
                const categoryValue = price * quantity;
                return array.push(categoryValue);
            });
            const totalValue = array.reduce((a, b) => {
                return a + b;
            }, 0);
            state.totalStoreValue = totalValue;
        },

        UPDATE_LAST10_OF_CATEGORY(state, action) {
            const array = state.categories.filter((fil) => fil._id === action.payload.category)[0].history;
            if (array.length >= 10) {
                array.pop();
            }

            array.unshift({ history: action.payload });

            const newState = state.categories.map((category) => {
                if (category._id === action.payload.category) {
                    category.history = array
                }
                return category
            })

            state.categories = newState;
        },

        UPDATE_SUBCATEGORY(state, action) {
            const newState = state.subcategories.map((subcat) => {
                if (subcat._id === action.payload.newData._id) {
                    return {
                        ...subcat,
                        stock: action.payload.newData.stock,
                        vendidoQty: action.payload.newData.vendidoQty,
                        vendidoBalance: action.payload.newData.vendidoBalance,
                        tipoentrega: action.payload.newData.tipoentrega,
                        name: action.payload.newData.name,
                        discountuser: action.payload.newData.discountuser,
                        costouser: action.payload.newData.costouser,
                        costoreseller: action.payload.newData.costoreseller,
                        policy: action.payload.newData.policy,
                        requiredemail: action.payload.newData.requiredemail,
                        enabled: action.payload.newData.enabled,
                        renovable: action.payload.newData.renovable,
                        url: action.payload.newData.url,
                        logo: action.payload.newData.logo,
                        location: action.payload.newData.location
                    }
                }
                return subcat
            })
            state.subcategories = newState
        },

        UPDATE_CATEGORY(state, action) {
            const newState = state.categories.map((cat) => {
                if (cat._id === action.payload.newData._id) {
                    return {
                        ...cat,
                        name: action.payload.newData.name,
                        logo: action.payload.newData.logo,
                        sublogo: action.payload.newData.sublogo,
                        themecolor: action.payload.newData.themecolor,
                        enabled: action.payload.newData.enabled,
                        description: action.payload.newData.description,
                        last_update: action.payload.newData.last_update
                    }
                }
                return cat
            })
            state.categories = newState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCategory.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.type = action.payload.type;
                if (action.payload.type === 'Streaming' || action.payload.type === 'GiftCard') {
                    state.isMutation.extra.newsubcategory = action.payload.newsubcategory
                    state.subcategories.push(action.payload.newsubcategory)
                } else {
                    state.isMutation.extra.newcategory = action.payload.newcategory
                    state.categories.push(action.payload.newcategory);
                }
                state.isMutation.success = true;
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET CATEGORIES
            .addCase(getCategories.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload.categories;
                state.emails = action.payload.emails
                state.isMutation.loading = false;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET SUBCATEGORIES
            .addCase(getSubCategories.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getSubCategories.fulfilled, (state, action) => {
                state.subcategories = action.payload;
                state.isMutation.loading = false;
            })
            .addCase(getSubCategories.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // UPDATE CATEGORY

            .addCase(updateCategory.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.newData._id) {
                        return {
                            ...cat,
                            name: action.payload.newData.name,
                            logo: action.payload.newData.logo,
                            sublogo: action.payload.newData.sublogo,
                            themecolor: action.payload.newData.themecolor,
                            enabled: action.payload.newData.enabled,
                            description: action.payload.newData.description,
                            last_update: action.payload.newData.last_update
                        }
                    }
                    return cat
                })
                state.categories = newStateCategory
                state.isMutation.extra.category = action.payload.newData
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE SUBCATEGORY

            .addCase(updateSubCategory.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateSubCategory.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newStateCategory = state.subcategories.map((cat) => {
                    if (cat._id === action.payload.newData._id) {
                        cat = action.payload.newData
                    }
                    return cat
                })
                state.subcategories = newStateCategory;
                state.isMutation.extra.subcategory = action.payload.newData;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateSubCategory.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            .addCase(deleteCategory.pending, (state) => {
                state.isMutation.dloading = true;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.categories = state.categories.filter(fil => fil._id !== action.meta.arg)
                state.isMutation.dloading = false;
                state.isMutation.updated = true;
            })

            .addCase(deleteCategory.rejected, (state, action) => {
                state.isMutation.dloading = false;
            })

            .addCase(deleteSubCategory.pending, (state) => {
                state.isMutation.dloading = true;
            })

            .addCase(deleteSubCategory.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.subcategories = state.subcategories.filter(fil => fil._id !== action.payload.id)
                state.isMutation.dloading = false;
                state.isMutation.updated = true;
            })
            .addCase(deleteSubCategory.rejected, (state, action) => {
                state.isMutation.dloading = false;
            })
    },
});

export const {
    CALC_STORE_CATEGORY_VALUE,
    UPDATE_CATEGORY,
    UPDATE_SUBCATEGORY,
    SET_TO_DEFAULT_RESPONSE,
    UPDATE_LAST10_OF_CATEGORY,
    NEW_CATEGORY,
    NEW_SUBCATEGORY,
    SET_CATEGORY_SUBCATEGORY
} = categorySlice.actions;

export default categorySlice.reducer;

