import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashboardService from "../services/dashboardService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} },
    newusers: { total: 0, year: 0, month: 0, week: 0, today: 0 },
    recargas: { data: [], total: 0, year: 0, month: 0, today: 0 },
    ventas: {
        top5Streaming: { total: [], year: [], month: [], today: [] },
        top5GiftCard: { total: [], year: [], month: [], today: [] },
        top5Social: { total: [], year: [], month: [], today: [] },
        total: 0, year: 0, month: 0, today: 0
    },
    bestseller: { name: "Sin datos", total: 0 },
    last10Ventas: []
};

export const getRecargasDashboard = createAsyncThunk(
    "dashboard/getRecargasDashboard",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.getRecargasDashboard();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getVentasDashboard = createAsyncThunk(
    "dashboard/getVentasDashboard",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.getVentasDashboard();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getNewUsersDashboard = createAsyncThunk(
    "dashboard/getNewUsersDashboard",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.getNewUsersDashboard();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBestSellerDashboard = createAsyncThunk(
    "dashboard/getBestSellerDashboard",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.getBestSellerDashboard();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getLast10VentasDashboard = createAsyncThunk(
    "dashboard/getLast10VentasDashboard",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.getLast10VentasDashboard();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} }
        },
    },
    extraReducers: (builder) => {
        builder
            // GET RECARGAS DASHBOARD

            .addCase(getRecargasDashboard.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getRecargasDashboard.fulfilled, (state, action) => {
                state.recargas.data = action.payload.data;
                state.recargas.total = action.payload.total;
                state.recargas.year = action.payload.year;
                state.recargas.month = action.payload.month;
                state.recargas.today = action.payload.today;
                state.isMutation.loading = false;
            })
            .addCase(getRecargasDashboard.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET VENTAS DASHBOARD

            .addCase(getVentasDashboard.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getVentasDashboard.fulfilled, (state, action) => {
                state.ventas.top5Streaming.total = action.payload.top5Streaming.sort((a, b) => b.total - a.total).slice(0, 5);
                state.ventas.top5Streaming.year = action.payload.top5Streaming.sort((a, b) => b.totalCurrentYear - a.totalCurrentYear).slice(0, 5);
                state.ventas.top5Streaming.month = action.payload.top5Streaming.sort((a, b) => b.totalCurrentMonth - a.totalCurrentMonth).slice(0, 5);
                state.ventas.top5Streaming.today = action.payload.top5Streaming.sort((a, b) => b.totalToday - a.totalToday).slice(0, 5);

                state.ventas.top5GiftCard.total = action.payload.top5GiftCard.sort((a, b) => b.total - a.total).slice(0, 5);
                state.ventas.top5GiftCard.year = action.payload.top5GiftCard.sort((a, b) => b.totalCurrentYear - a.totalCurrentYear).slice(0, 5);
                state.ventas.top5GiftCard.month = action.payload.top5GiftCard.sort((a, b) => b.totalCurrentMonth - a.totalCurrentMonth).slice(0, 5);
                state.ventas.top5GiftCard.today = action.payload.top5GiftCard.sort((a, b) => b.totalToday - a.totalToday).slice(0, 5);

                state.ventas.top5Social.total = action.payload.top5Social.sort((a, b) => b.total - a.total).slice(0, 5);
                state.ventas.top5Social.year = action.payload.top5Social.sort((a, b) => b.totalCurrentYear - a.totalCurrentYear).slice(0, 5);
                state.ventas.top5Social.month = action.payload.top5Social.sort((a, b) => b.totalCurrentMonth - a.totalCurrentMonth).slice(0, 5);
                state.ventas.top5Social.today = action.payload.top5Social.sort((a, b) => b.totalToday - a.totalToday).slice(0, 5);

                state.ventas.total = action.payload.total;
                state.ventas.year = action.payload.year;
                state.ventas.month = action.payload.month;
                state.ventas.today = action.payload.today;
                state.isMutation.loading = false;
            })
            .addCase(getVentasDashboard.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET NEW USERS DASHBOARD

            .addCase(getNewUsersDashboard.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getNewUsersDashboard.fulfilled, (state, action) => {
                state.newusers.total = action.payload.total;
                state.newusers.year = action.payload.year;
                state.newusers.month = action.payload.month;
                state.newusers.week = action.payload.week;
                state.newusers.today = action.payload.today;
                state.isMutation.loading = false;
            })
            .addCase(getNewUsersDashboard.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BEST SELLER

            .addCase(getBestSellerDashboard.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBestSellerDashboard.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.bestseller.name = action.payload.name;
                state.bestseller.total = action.payload.total;
            })
            .addCase(getBestSellerDashboard.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET LAST 10 VENTAS

            .addCase(getLast10VentasDashboard.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getLast10VentasDashboard.fulfilled, (state, action) => {
                state.last10Ventas = action.payload.last10Ventas;
                state.isMutation.loading = false;
            })
            .addCase(getLast10VentasDashboard.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { SET_TO_DEFAULT_RESPONSE } = dashboardSlice.actions;

export default dashboardSlice.reducer;

