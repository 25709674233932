import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ImagesPreviewBanner2 = ({ url, heading }) => {
    return (
        <div className="flex items-center">
            {url &&
                <div className="flex items-center">
                    <LazyLoadImage
                        className="object-cover rounded-xs max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]"
                        alt={`No image`}
                        effect="opacity"
                        src={url}
                    />
                </div>}
        </div>
    )
}
export default ImagesPreviewBanner2;