import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gcventaService from "../services/gcventaService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} },
    contador: { pending: 0, rejected: 0, total: 0, loading: false },
    ventasByPage: [],
    ventas: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
};

export const create = createAsyncThunk(
    "gcventa/create",
    async (formData, thunkAPI) => {
        try {
            return await gcventaService.create(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const completarPedido = createAsyncThunk(
    "gcventa/completarpedido",
    async (formData, thunkAPI) => {
        try {
            return await gcventaService.completarPedido(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const rechazarPedido = createAsyncThunk(
    "gcventa/rechazarPedido",
    async (formData, thunkAPI) => {
        try {
            return await gcventaService.rechazarPedido(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAll = createAsyncThunk(
    "gcventa/getAll",
    async (_, thunkAPI) => {
        try {
            return await gcventaService.getAll();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBypage = createAsyncThunk(
    "gcventa/getBypage/ventabypage",
    async (query, thunkAPI) => {
        try {
            return await gcventaService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getByPageByUserId = createAsyncThunk(
    "gcventa/getByPageByUserId/ventasbyuserId",
    async (query, thunkAPI) => {
        try {
            return await gcventaService.getByPageByUserId(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "gcventa/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await gcventaService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const del = createAsyncThunk(
    "gcventa/del/venta",
    async (data, thunkAPI) => {
        try {
            return await gcventaService.del(data.id, data.type);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const gcventaSlice = createSlice({
    name: "gcventa",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} }
        },

        UPDATE_GCVENTA_FROM_SOCKET(state, action) {

        },
    },
    extraReducers: (builder) => {
        builder

            // CREATE
            .addCase(create.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.amount = action.payload.amount;
                state.isMutation.extra.newHistory = action.payload.newHistory;
                state.isMutation.extra.newDataSubcategory = action.payload.newData;
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })
            .addCase(create.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // COMPLETAR PEDIDO
            .addCase(completarPedido.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(completarPedido.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newStateVenta._id) {
                        venta = action.payload.newStateVenta
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.contador.pending -= 1
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(completarPedido.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // RECHAZAR PEDIDO
            .addCase(rechazarPedido.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(rechazarPedido.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newStateVenta._id) {
                        venta = action.payload.newStateVenta
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.contador.pending -= 1
                state.contador.rejected += 1
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(rechazarPedido.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // GET ALL

            .addCase(getAll.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.ventas = action.payload;
            })
            .addCase(getAll.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.ventasByPage = action.payload.ventas;
                state.totalFilter = action.payload.totalventasFilter;
                if (action.payload.totalventas !== state.contador.total) {
                    state.contador.total = action.payload.totalventas
                }
                state.total = action.payload.totalventas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BY_USER_ID

            .addCase(getByPageByUserId.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getByPageByUserId.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.ventasByPage = action.payload.ventas;
                state.totalFilter = action.payload.totalventasFilter;
                state.total = action.payload.totalventas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getByPageByUserId.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.pending = action.payload.totalventasPending;
                state.contador.rejected = action.payload.totalventasRejected;
                state.contador.total = action.payload.totalventas;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // DELETE

            .addCase(del.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(del.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })

            .addCase(del.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { UPDATE_GCVENTA_FROM_SOCKET, SET_TO_DEFAULT_RESPONSE } = gcventaSlice.actions;

export default gcventaSlice.reducer;

